<template>
  <div
    class="height100"
    style="overflow-y: hidden; background: #F8F9FB;"
  >
    <div class="dashboard_top_filter_wrap">
      <!--상단 우측 필터 부분-->
      <div class="flex justify-space-between">
        <div
          v-if="!actionMode"
          class="pa-2 chip_wrap"
        >
          <template>
            <vue-switch
              v-model="searchOptions.onlyMe"
              color="#BCD1E2"
              :true-text="'본인' | translate"
              :false-text="'전체' | translate"
              true-text-color="#0c98fe"
              false-text-color="#EC4DA3"
              true-background-color="#C9E8FF"
              false-background-color="#FBD8EB"
              class="switch"
            />
          </template>
          <template>
            <v-chip
              v-for="(item, index) in chips"
              :key="index"
              class="mr-1"
              :color="item.color || '#EDD8FB'"
            >
              <div
                style="white-space: nowrap; overflow-x: hidden; text-overflow: ellipsis;"
              >
                {{ item.text }}
              </div>
            </v-chip>
          </template>
        </div>
        <div class="flex flex_end">
          <div :style="actionMode ? 'width:100%' : ''">
            <template v-if="actionMode">
              <ToolbarSearchAction
                ref="toolbarSearchAction"
                :value="searchOptions.searchKeyword"
                :loading="loading"
                :placeholder="'거래처/주소' | translate"
                :style="'width:100%;'"
                always-active
                @close="actionMode=false"
                @search="onSearchClicked"
              />
            </template>
            <template v-else>
              <v-btn
                icon
                @click="actionMode = true"
              >
                <img src="@/assets/images/main/svg/buttonSearch.svg">
              </v-btn>
            </template>
          </div>
          <div
            v-if="!actionMode"
            class="filter_wrap flex_end"
          >
            <div
              @click="onDialog"
            >
              <div class="filter-icon" />
            </div>
          </div>
        </div>
        <consult-note-search-filter-modal
          :search-options.sync="searchOptions"
          :dialog="dialog"
          :acc-level-options="accLevelOptions"
          @onDialogClose="onDialog"
          @status-options-items="statusOptionsItems"
        />
      </div>
      <!-- 필터 -->
    </div>
    
    <!-- 인피니트 리스트 -->
    <vue-infinite-loader
      :items="items"
      :fetch="fetch"
      :identifier="identifier"
      item-key="raw.accountLogId"
    >
      <template #default="{ item }">
        <consult-note-card
          :item="item"
          @click:consult-item="onDetailClicked"
        />
      </template>
    </vue-infinite-loader>

    <!-- 새로고침 버튼 -->
    <refresh-button
      :container="mounted ? $refs.container : undefined"
      @click="onRefresh"
    />
  </div>
</template>

<script>
import {PATH} from "@/constants";
import Account from "@/model/account";
import OnMainFilterTriggered from "@/event/OnMainFilterTriggered";
import VuexProps from "@/framework/vuex/VuexProps";
import OnFilterResetListener from "@/framework/listeners/OnFilterResetListener";
import RefreshButton from "@/components/comp/RefreshButton";

import OnMainSearchTriggered from "@/event/OnMainSearchTriggered";
import ConsultNoteCard from "@/views/home/ConsultNoteCard.vue";
import ToolbarSearchAction from "@/components/app/toolbar/ToolbarSearchAction.vue";
import ConsultNoteSearchFilterModal from "@/components/vuetify-modal/filter/ConsultNoteSearchFilterModal.vue";
import CodeOptions from "@/components/mixins/CodeOptions.vue";

export default {
  name: "ConsultNote",
  components: {
    ConsultNoteSearchFilterModal,
    ToolbarSearchAction,
    ConsultNoteCard,
    RefreshButton,
  },
  mixins: [
    CodeOptions,
    OnMainSearchTriggered,
    OnMainFilterTriggered,
    VuexProps,
    OnFilterResetListener],
  props: {},
  data() {
    return {
      dialog: false,
      menu: false,
      modal: false,
      // active: true,
      items: [],
      chips: [],
      searchOptions: {
        sortBy: [],
        sortDesc: [],
        searchKeyword: null,
        startDate: null,
        endDate:null,
        useYn: 'Y',
        accLevelType: null,
        onlyMe: false,
      },
      accLevelOptions: [],
      identifier: 0,
      mounted: false,
      actionMode: false,
    }
  },
  watch: {
    searchOptions: {
      deep: true,
      handler() {
        this.identifier++
      }
    },
  },

  mounted() {
    this.mounted = true;
    if (this.historyManager.isForward) { // pushState 한 경우만 초기화
      this.identifier++;
    } else if (this.historyManager.isBack) {
      this.loadAllUpToCurrent();
    }
    this.loadOptionsForAccLevel()
  },
  methods: {
    async loadOptionsForAccLevel() {
      try {
        const response = await this.loadRentalcompanyCodes("ACC_LEVEL_TYPE");
        this.accLevelOptions = response.data;
        this.accLevelOptions.unshift({
          codeKey: null, codeValue: '전체'
        })
      } catch (e) {
        //do nothing.
      }
    },

    onSearchClicked(keyword) {
      console.log('### onSearchClicked')
      this.searchOptions.searchKeyword = keyword
    },

    statusOptionsItems() {
      let arr = []

      let dateStartText = null
      let dateEndText = null
      if (!this.$isNOU(this.searchOptions.startDate)) {
        dateStartText = this.$date(this.searchOptions.startDate);
      }

      if (!this.$isNOU(this.searchOptions.endDate)) {
        dateEndText = this.$date(this.searchOptions.endDate);
      }

      if (!this.$isNOU(dateStartText) || !this.$isNOU(dateEndText)) {
        arr.push({text: (dateStartText || "") + " ~ " + (dateEndText || "")});
      }


      if (this.searchOptions.accLevelType !== undefined) {
        this.accLevelOptions.filter(e => this.searchOptions.accLevelType === e.codeKey)
            .map(e => e.codeValue).forEach(e => arr.push({text: e}));
      }
      this.chips = arr
    },
    /****
     * 필터 다이얼로그 닫기
     */
    onDialogClose() {
      console.log('### onDialogClose')
      this.$emit('onDialogClose')
    },

    onDialog() {
      console.log('### onDialog')
      this.dialog = !this.dialog;
    },

    /**
     * 작업일지 상세 화면 오픈
     * params 는 선택하는 아이템의 조건을 보내준다.
     * @param item
     */
    onDetailClicked(item) {
      console.log('### onDetailClicked')
      this.routerManager.push({name: "ConsultNoteDetail" , params: {accountLogId: item.raw.accountLogId}});
    },

    async loadAllUpToCurrent() {
      console.log('### loadAllUpToCurrent')
      const identifier = this.identifier;
      const response = await this.$request(PATH.CONSULT_NOTE.LIST)
          .setObject({
            ...this.searchOptions,
            pageNumber: 1,
            pageSize: this.searchOptions.pageNumber * this.searchOptions.pageSize,
          })
          .wait(500)
          .enqueue();
      if (identifier !== this.identifier) return;
      this.items = response.data.map(e => new Account(e));
    },

    async fetch(page, pageSize) {
      console.log('### fetch')
      this.searchOptions.pageNumber = page;
      this.searchOptions.pageSize = pageSize;

      const response = await this.$request(PATH.CONSULT_NOTE.LIST)
          .setObject(this.searchOptions)
          .catch()
          .wait(500)
          .enqueue();
      return response.data.map(e => new Account(e));
    },

    /**
     * 검색키워드와 필터 조건을 초기화 합니다.
     */
    // initSearchOptions() {
    //
    // },

    //
    // onMainFilterTriggered(state) {
    //   this.searchOptions = {...this.searchOptions, ...state};
    //   // this.identifier++;
    // },

    /**
     * 필터 조건을 초기화하고 리로드합니다.
     */
    // onFilterReset() {
      // this.initSearchOptions();
      // this.identifier++;
    // },

    /**
     * 새로고침 버튼을 클릭하였습니다.
     *
     * 필터조건 변경없이 목록 데이터만 새로 로드합니다.
     */
    onRefresh() {
      console.log('### onRefresh')
      this.identifier++;
    },

  }
}
</script>

<style scoped>

.dashboard_top_filter_wrap {
  display: flex;
  padding-bottom: 3px;
}

.date_picker_wrap .v-input {
  margin: 0;
  padding: 0;
}

.date_picker_wrap >>> .v-text-field__details {
  display: none;
}

.date_picker_wrap >>> .v-text-field input {
  padding: 4px;
  background-image: url(/img/dashboard_arrow.svg);
  background-repeat: no-repeat;
  background-position: 92% center;
  background-size: 8px;
  font-size: 0.917rem;
}

.date_picker_wrap >>> .v-text-field__slot {
  background-color: white;
  border: 1px solid #ccc;
  width: 112px;
  height: 34px;
  border-radius: 4px;
  margin-right: 2.8%;
}

.date_picker_wrap >>> .v-input__slot:before {
  display: none;
}

.date_picker_wrap >>> .v-input__slot:after {
  display: none;
}

.filter_wrap {
  display: flex;
  align-items: center;
  /* padding: 2px 8px;
   !*width: 130px;*!
   height: 34px;
   background: #fff;
   border: 1px solid #ccc;
   border-radius: 4px;
   justify-content: space-between;
   margin-bottom: 4px;*/
}

.filter_wrap div {
  width: 35px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
}


.filter_wrap div img {
  width: 100%;
}

.dashboard_right_contents_wrap {
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 12px;
  background: #F8F9FB;
}

.dashboard_right_content {
  display: inline-block;
  vertical-align: top;
  /*height: 572px;*/
  /*min-height: 412px;*/
}

* {
  font-family: "Noto Sans KR", sans-serif;
}

.printer-icon {
  width: 18px;
  height: 18px;
  background-image: url("/img/dashboard/dashboard_print_icon.svg");
}

.printer-icon:hover {
  background-image: url("/img/dashboard/dashboard_print_color_icon.svg");
}

.mail-icon {
  width: 18px;
  height: 18px;
  background-image: url("/img/dashboard/dashboard_mail_icon.svg");
}

.mail-icon:hover {
  background-image: url("/img/dashboard/dashboard_mail_color_icon.svg");
}

.filter-icon {
  width: 18px;
  height: 18px;
  background-image: url("/img/dashboard/dashboard_filter_icon.svg");
  background-size: 26px;
}

.filter-icon:hover {
  background-image: url("/img/dashboard/dashboard_filter_color_icon.svg");
}


.switch {
  font-size: 0.8125rem;
}
.v-chip.v-size--default {
  border-radius: 14px;
  color: white;
  font-size: 12px;
  height: 28px;
}

.chip_wrap {
  display: block;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  text-align: left;
}


.close_button_wrap {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 49px;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  right: 0;
  z-index: 1;
}

.close_button_wrap > img {
  position: absolute;
}

.v-chip.v-size--default {
  color: #AF4DEC;
}

>>>.v-input--switch .v-input--switch__thumb {
  background-color: white !important;
}

>>> .v-input--switch--inset .v-input--switch__track {
  opacity: 1;
}

</style>